import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import "./scss/index.scss"

const initData = {
    heading: "Tracking Number",
    content: "Please enter your tracking number",
    btnText: "Notify"
}

class TrackSection extends Component {
    state = {
        initData: []
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    render() {
        return (
            <div className="homepage-3 inner inner-pages">
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <section id="home" className="section welcome-area inner-area bg-overlay h-100vh overflow-hidden">
                        <div className="container h-100">
                            <div className="row align-items-center justify-content-center h-100">
                                <div className="col-12 col-md-7">
                                    <div className="welcome-intro text-center">
                                        <h2 className="text-white">{this.state.initData.heading}</h2>
                                        <p className="text-white my-4">{this.state.initData.content}</p>
                                        <div className="subscribe-form d-flex align-items-center mx-auto">
                                            <input type="number" required className="form-control" placeholder="Ex. 123456789" />
                                            <button type="submit" className="track__button"><i className="text-white fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default TrackSection;