import React from "react";


function ServiceOne () {
  const data = {
    "heading": "Box Size (L X W X H) in *cm",
    "headingTwo": "Flammable materials, Pirated Products DVD'S CD'S Tapes etc. Firearms, Ammunitions, and Explosive, Pornographic Materials, Gambling Cards, and Toy Guns, Prohibited Drugs and Other Substance, Currencies, Checks, Money, and Orders and Traveler's Check, Jewelries",
    "headingThree": "Send happiness to your love ones with our competitive price  and high quality balikbayan box service.",
    "headingFour": "More productivity with less effort",
    "headingFive": "Organize all your media content easily",
    "headingSix": "Work faster with powerful filters",
    "headingSeven": "Stay connected with all of your friends",
    "buttonText": "Learn More",
    "thumbOne": "/img/features_thumb.png",
    "thumbTwo": "/img/service_thumb_2.png",
    "thumbThree": "img/featureOne.jpg",
    "thumbFour": "/img/discover_thumb.png",
    "thumbFive": "/img/service_thumb_1.png",
    "thumbSix": "/img/discover_thumb_2.png",
    "serviceData": [
      {
        "id": 1,
        "iconClass": "fab fa-buffer",
        "text": "Medium (51 x 51 x 51)"
      },
      {
        "id": 2,
        "iconClass": "fas fa-brush",
        "text": "Large with Bulilit (56 x 56 x 76)"
      },
      {
        "id": 3,
        "iconClass": "fas fa-brush",
        "text": "Large Plus with Bulilit (56 x 56 x 96)"
      },
      {
        "id": 4,
        "iconClass": "fas fa-brush",
        "text": "Extra Large with Bulilit (71 x 53 x 91)"
      },
    ]
  }
    return (
      <>
      <section className="section service-area bg-inherit overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-5 order-2 order-lg-1 align-self-center">
              {/* Service Text */}
              <div className="service-text pt-4 pt-lg-0 px-0 px-lg-4">
                {/* <h2 className="text-capitalize mb-4">{this.state.data.headingThree}</h2> */}
                <h2 className="text-capitalize mb-3">Sea Cargo Rates</h2>
                <p className="mb-3">{data.headingThree}</p>
                <span>{data.heading}</span>
                {/* Service List */}
                <ul className="service-list style-two">
                  {/* Single Service */}
                  {data.serviceData.map((item, idx) => {
                    return (
                      <li key={`sth_${idx}`} className="single-service py-2">
                        <div className="service-text">
                          <p>{item.text}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <span className="d-block"><strong>PROHIBITED ITEMS: </strong>{data.headingTwo}</span>
                {/* <a href="/#" className="btn btn-bordered mt-4">
                  {this.state.data.buttonText}
                </a> */}
                <a href="https://m.me/PEPExpressCargoUAE" className="btn btn-bordered mt-4">
                  Order Now
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-7 order-1 order-lg-2 d-md-block">
              {/* Service Thumb */}
              <div className="service-thumb mx-auto">
                <img src={data.thumbThree} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
    );
}

export default ServiceOne;
