import React, { Component } from "react";
import axios from "axios";
import "./scss/index.scss"

const BASE_URL =
  "https://my-json-server.typicode.com/themeland/json-server-1/themeOneFooterSection";

class FooterSection extends Component {
  state = {
    data: {
      image: "/img/pep_footer.png",
      text:
        "Our Service was built to help our new age heroes (OFW).",
      textFour: "We want to create modern and trust-worthy logistics provider that fits the need of OFW's.",
      textTwo: "Ang bago mong kaagapay sa pagpapadala ng balikbayan box sa mga mahal nyo sa buhay sa Pinas.",
      textThree: "Our company is a part of Philippine Eastern Company based in Manila, Philippines and have been doing business for 40 years.",
      linkText_1: "Our Products and Services",
      linkText_2: "Connect with Us",
      linkText_3: "Download",
      iconList: [
        {
          id: 1,
          link: "facebook",
          iconClass: "fab fa-facebook-f",
          address: "https://www.facebook.com",
        },
        {
          id: 2,
          link: "twitter",
          iconClass: "fab fa-twitter",
          address: "https://www.facebook.com",
        },
        {
          id: 3,
          link: "google-plus",
          iconClass: "fab fa-google-plus-g",
          address: "https://www.facebook.com",
        },
        {
          id: 4,
          link: "vine",
          iconClass: "fab fa-vine",
          address: "https://www.facebook.com",
        },
      ],
      footerList_1: [
        {
          id: 1,
          text: "Home",
        },
        {
          id: 2,
          text: "About Us",
        },
        {
          id: 3,
          text: "Services",
        },
        {
          id: 4,
          text: "Blog",
        },
        {
          id: 5,
          text: "Contact",
        },
      ],
      footerList_2: [
        {
          id: 1,
          text: "FAQ",
        },
        {
          id: 2,
          text: "Privacy Policy",
        },
        {
          id: 3,
          text: "Support",
        },
        {
          id: 4,
          text: "Terms & Conditions",
        },
        {
          id: 5,
          text: "Contact",
        },
      ],
      footerList_3: [
        {
          id: 1,
          image: "/img/google-play-black.png",
        },
        {
          id: 2,
          image: "/img/app-store-black.png",
        },
      ],
      footerList_4: [
        {
          id: 1,
          text: "Affiliate program",
        },
        {
          id: 2,
          text: "Terms & Conditions",
        },
        {
          id: 3,
          text: "Privacy Policy",
        },
        {
          id: 4,
          text: "Refund Policy",
        },
      ],
    },
    iconList: [
      {
        id: 1,
        link: "facebook",
        iconClass: "fab fa-facebook-f",
        address: "https://www.facebook.com",
      },
      {
        id: 2,
        link: "twitter",
        iconClass: "fab fa-twitter",
        address: "https://www.facebook.com",
      },
      {
        id: 3,
        link: "google-plus",
        iconClass: "fab fa-google-plus-g",
        address: "https://www.facebook.com",
      },
      {
        id: 4,
        link: "vine",
        iconClass: "fab fa-vine",
        address: "https://www.facebook.com",
      },
    ],
    footerList_1: [
      {
        id: 1,
        text: "Home",
      },
      {
        id: 2,
        text: "Services",
      },
      // {
      //   id: 3,
      //   text: "Contact",
      // },
      // {
      //   id: 4,
      //   text: "Blog",
      // },
      // {
      //   id: 5,
      //   text: "Contact",
      // },
    ],
    footerList_2: [
      {
        id: 1,
        text: "Contact",
      },
      {
        id: 2,
        text: "Terms and Conditions",
      },
      {
        id: 3,
        text: "Privacy Policy",
      },
      // {
      //   id: 4,
      //   text: "Terms & Conditions",
      // },
      // {
      //   id: 5,
      //   text: "Contact",
      // },
    ],
    footerList_3: [
      {
        id: 1,
        image: "/img/google-play-black.png",
      },
      {
        id: 2,
        image: "/img/app-store-black.png",
      },
    ],
  };
  componentDidMount() {
    // axios
    //   .get(this.state.data)
    //   .then((res) => {
    //     this.setState({
    //       data: res.data,
    //       iconList: res.data.iconList,
    //       footerList_1: res.data.footerList_1,
    //       footerList_2: res.data.footerList_2,
    //       footerList_3: res.data.footerList_3,
    //     });
    //     // console.log(this.state.data)
    //   })
    //   .catch((err) => console.log(err));
  }
  render() {
    return (
      <div>
        <hr></hr>
        <div className="height-emulator d-none d-lg-block" />
        {/* <footer className="footer footer-area footer-fixed"> */}
        <footer className="footer footer-area">
          {/* Footer Top */}
          <div className="footer-top ptb_50">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-6">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Logo */}
                    <a className="footer-items__logo" href="/#">
                      <img
                        src={this.state.data.image}
                        alt=""
                      />
                    </a>
                    <div className="mt-2">
                    <span>{this.state.data.text}</span>
                    <span >{this.state.data.textFour}</span>
                    </div>
                    {/* Social Icons */}
                    {/* <div className="social-icons d-flex">
                      {this.state.iconList.map((item, idx) => {
                        return (
                          <a
                            key={`fi_${idx}`}
                            className={item.link}
                            href={item.address}
                            target="_blank"
                          >
                            <i className={item.iconClass} />
                            <i className={item.iconClass} />
                          </a>
                        );
                      })}
                    </div> */}
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_1}
                    </h3>
                    <ul>
                      {this.state.footerList_1.map((item, idx) => {
                        return (
                          <li key={`flo_${idx}`} className="py-2">
                            <a href="/#">{item.text}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                  {/* Footer Items */}
                  <div className="footer-items">
                    {/* Footer Title */}
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_2}
                    </h3>
                    <ul>
                      {this.state.footerList_2.map((item, idx) => {
                        return (
                          <li key={`flt_${idx}`} className="py-2">
                            <a href="/#">{item.text}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {/* <div className="col-12 col-sm-6 col-lg-3">
                  <div className="footer-items">
                    <h3 className="footer-title mb-2">
                      {this.state.data.linkText_3}
                    </h3>
                    <div className="button-group store-buttons store-black d-flex flex-wrap">
                      {this.state.footerList_3.map((item, idx) => {
                        return (
                          <a key={`flth_${idx}`} href="/#">
                            <img src={item.image} alt="" />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="copyright-area">
                    <div className="mt-3 text-center">
                      © Copyrights 2020 PEP Delivery Solutions.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default FooterSection;
