import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import FooterSection from '../FooterSection/Footer';
import "./scss/index.scss"
import TrackSection from './TrackSection';

class TrackPage extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <div className="track__background">
                    {/* <Breadcrumb title="Track Here" /> */}
                    </div>
                    <TrackSection />
                    {/* <ServiceTwo />
                    <ServiceThree /> */}

                    {/* <DownloadSection />
                    <ContactSection /> */}
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default TrackPage;