import React from 'react';
import "./scss/index.scss"

import { Carousel } from 'react-bootstrap';

const ServiceSection = (props) => {
    const data = {
        "heading": "Make your Device Manage Everything For you",
        "headingTwo": "Ship and Get Rewarded!",
        "headingThree": "Smart and secure mobile banking",
        "headingFour": "More productivity with less effort",
        "headingFive": "Organize all your media content easily",
        "headingSix": "Work faster with powerful filters",
        "headingSeven": "Stay connected with all of your friends",
        "buttonText": "Learn More",
        "thumbOne": "/img/features_thumb.png",
        "thumbTwo": "/img/service_thumb_2.png",
        "thumbThree": "/img/welcome_mockup_2.png",
        "thumbFour": "/img/discover_thumb.png",
        "thumbFive": "/img/service_thumb_1.png",
        "thumbSix": "/img/discover_thumb_2.png",
        "serviceData": [
          {
            "id": 1,
            "iconClass": "fab fa-buffer",
            "text": "Fully layered dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam."
          },
          {
            "id": 2,
            "iconClass": "fas fa-brush",
            "text": "Customizable design dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam."
          },
          {
            "id": 3,
            "iconClass": "fas fa-burn",
            "text": "Drop ipsum dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam."
          },
          {
            "id": 4,
            "iconClass": "fas fa-cart-arrow-down",
            "text": "Marketing chart dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam."
          }
        ]
      }
        return (
            <section className={props.isRewardsPage ? "section service-area bg-inherit overflow-hidden" :"section service-area bg-inherit overflow-hidden ptb_75"}>
                <div className="container">
                    {props.isRewardsPage ? '' :
                    <h2 className="text-center mb-4">{data.headingTwo}</h2>}
                     {/* <div className="row justify-content-between">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        <div className="service-text pt-4 pt-lg-0">
                        <h2 className="mb-4">{this.state.data.headingTwo}</h2>
                        <ul className="service-list">
                            {this.state.serviceData.map((item, idx) => {
                                return(
                                    <div key={`st_${idx}`}>
                                        <li className="single-service media py-2">
                                        <div className="service-icon pr-4">
                                            <span><i className={item.iconClass} /></span>
                                        </div>
                                        <div className="service-text media-body">
                                            <p>{item.text}</p>
                                        </div>
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                        <a href="/#" className="btn btn-bordered mt-4">{this.state.data.buttonText}</a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-md-block">
                        <div className="service-thumb mx-auto">
                            <img src={this.state.data.thumbTwo} alt="" />
                        </div>
                    </div>
                    </div> */}
                </div>
                <div className="service-area__carousel">
                    <Carousel touch 
                    >
                        <Carousel.Item>
                            <img
                            className="d-block w-100 mx-auto"
                            src="/img/service_one.jpg"
                            alt="First slide"
                            />
                            <Carousel.Caption style={{backgroundColor: 'white', borderRadius: '10px'}}>
                            <h3>Ship at least 10 packages to get one (1) 32 inch Flat TV.</h3>
                            <p>Collect stamp to get 32" flat TV</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        {/* <Carousel.Item>
                            <img
                            className="d-block w-100 mx-auto"
                            src="/img/service_four.jpg"
                            alt="Third slide"
                            />
                            
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-50 mx-auto"
                            src="/img/service_two.jpg"
                            alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-50 mx-auto"
                            src="/img/service_three.jpg"
                            alt="Third slide"
                            />
                        </Carousel.Item> */}
                    </Carousel>
                </div>
            </section>
        );
    
}

export default ServiceSection;