export const baseUrl = "/";
export const ServiceUrl = `${baseUrl}services/`;
export const TrackUrl = `${baseUrl}track-here/`;
export const RewardsUrl = `${baseUrl}rewards/`;
export const ContactUrl = `${baseUrl}contact/`;
export const LoginUrl = `${baseUrl}login/`;
export const SignUpUrl = `${baseUrl}signup/`;

// export const MCTaxiUrl = `${baseUrl}services/mctaxi`;
// export const PabiliUrl = `${baseUrl}services/pabili`;
// export const DeliveryUrl = `${baseUrl}services/delivery`;
// export const TaxicleUrl = `${baseUrl}services/taxicle`;
// export const BusinessUrl = `${baseUrl}business`;

