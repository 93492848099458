import React from 'react';

const blogData = [
      {
        "id": 1,
        "image": "/img/service_two.jpg",
        "author": "Anna Sword",
        "date": "Feb 05, 2019",
        "title": "Year-End Promo!",
        "content": "Less 50 aed para sa ibang kahon. Pwede lamang sa Jumbo, Large, Super Jumbo, Mega Jumbo, Super Mega Jumbo, Extra Large, Drum",
        "btnText": "Read More"
      },
      {
        "id": 2,
        "image": "/img/service_three.jpg",
        "author": "Jassica William",
        "date": "Feb 05, 2019",
        "title": "PEP Express Cargo Caravan",
        "content": "with Free Flat TV, PEP Sportsbag, PEP Powerbanks, PEP Ballpen",
        "btnText": "Read More"
      },
    //   {
    //     "id": 3,
    //     "image": "/img/blog-3.jpg",
    //     "author": "John Doe",
    //     "date": "Mar 05, 2019",
    //     "title": "Make your successful business with sApp",
    //     "content": "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage.",
    //     "btnText": "Read More"
    //   },
    //   {
    //     "id": 4,
    //     "image": "/img/blog-4.jpg",
    //     "author": "Anna Sword",
    //     "date": "Aug 05, 2019",
    //     "title": "How to get more clients?",
    //     "content": "Need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.",
    //     "btnText": "Read More"
    //   },
     
    ]
const RewardsSection = () => {
    
        return (
                <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                <div className="row">
                    {blogData.map((item, idx) => {
                        return(
                            <div key={`bt_${idx}`} className="col-12 col-md-6 mb-5">
                                {/* Single Blog */}
                                <div className="single-blog res-margin">
                                    {/* Blog Thumb */}
                                    <div className="blog-thumb">
                                        <a><img src={item.image} alt="" /></a>
                                    </div>
                                    {/* Blog Content */}
                                    <div className="blog-content p-4">
                                        {/* Meta Info */}
                                        <ul className="meta-info d-flex justify-content-between">
                                        {/* <li>By <a className="text-primary" href="/#">{item.author}</a></li> */}
                                        {/* <li><a href="/#">{item.date}</a></li> */}
                                        </ul>
                                        {/* Blog Title */}
                                        <h3 className="blog-title my-3"><a href="#">{item.title}</a></h3>
                                        <p>{item.content}</p>
                                        {/* <a href="/#" className="blog-btn mt-3">{item.btnText}</a> */}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="row">
                    <div className="col-12">
                        {/* Pagination */}
                        {/* <ul className="pagination justify-content-center py-4">
                            <li className="disabled px-1">
                                <a href="/#" aria-label="Previous">
                                <i className="fas fa-arrow-left" />
                                </a>
                            </li>
                            <li className="px-1"><a href="/#">1</a></li>
                            <li className="active px-1"><a href="/#">2</a></li>
                            <li className="px-1"><a href="/#">3</a></li>
                            <li>
                                <a href="/#" aria-label="Next">
                                <i className="fas fa-arrow-right" />
                                </a>
                            </li>
                        </ul> */}
                    </div>
                </div>
            </div>
            </section>
        );
}

export default RewardsSection;