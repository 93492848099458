import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';


import FooterSection from '../FooterSection/Footer';
import "./scss/index.scss"
import ServiceSection from '../ServiceSection/ServiceTwo';
import RewardsSection from './RewardsSection';

class RewardsPage extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <div className="rewards__background">
                    <Breadcrumb title="Rewards and Promotions" />
                    </div>
                    <ServiceSection isRewardsPage={true} />
                    <RewardsSection/>

                    {/* <ServiceTwo />
                    <ServiceThree /> */}

                    {/* <DownloadSection />
                    <ContactSection /> */}
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default RewardsPage;