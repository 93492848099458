import React  from 'react';
import axios from 'axios';
import "./scss/index.scss"
import { Carousel } from 'react-bootstrap'

function FeatureSection () {
    const data =  {
        "preHeading": "Premium",
        "preHeadingspan": "Features",
        "heading": "What Makes sApp Different?",
        "headingTwo": "Our Services",
        "headingThree": "Why Small Business Owner's Loves sApp",
        "headingFour": "Why choose sApp?",
        "headingText": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
        "headingTexttwo": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
        "featureThumb": "/img/features_thumb.png",
        "featureData": [
          {
            "id": 1,
            "image": "/img/featured_image_1.png",
            "title": "Fully functional",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
          },
          {
            "id": 2,
            "image": "/img/featured_image_2.png",
            "title": "Live Chat",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
          },
          {
            "id": 3,
            "image": "/img/featured_image_3.png",
            "title": "Secure Data",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
          }
        ],
        "featureData_1": [
          {
            "id": 1,
            "image": "/img/featureOne.jpg",
            "title": "Sea Cargo Rates",
            "text": "Large with Bulilit 56x56x76 / 40x40x40",
            "price": "235",
            "currency" : "aed",
            "subtext": "for as low as"
          },
          {
            "id": 2,
            "image": "/img/featureTwo.jpg",
            "title": "Air Cargo Rates",
            "text": "Minimum of 30kgs",
            "price": "405",
            "currency" : "aed",
            "subtext": "7-15 days upon departure"
          },
          {
            "id": 3,
            "image": "/img/featureThree.jpg",
            "title": "TV Sea Cargo Rates",
            "text": "Free Wooden Crates for Visayas and Mindanao",
            "price": "300",
            "currency" : "aed",
            "subtext": "price may vary"
          }
        ],
        "featureDataTwo": [
          {
            "id": 1,
            "iconClass": "flaticon-tap",
            "title": "Fully functional",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
          },
          {
            "id": 2,
            "iconClass": "flaticon-fingerprint",
            "title": "Fingerprint Data",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
          },
          {
            "id": 3,
            "iconClass": "flaticon-notification",
            "title": "Tap Notification",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
          },
          {
            "id": 4,
            "iconClass": "flaticon-place",
            "title": "Location Tracking",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
          },
          {
            "id": 5,
            "iconClass": "flaticon-settings",
            "title": "Powerful Settings",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
          },
          {
            "id": 6,
            "iconClass": "flaticon-language",
            "title": "Multiple Language",
            "text": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis culpa expedita dignissimos."
          }
        ]
      }
        return (
        <>
            <section id="features" className="section features-area bg-overlay-features ptb_100 overflow-hidden">
                {/* <div className="shapes-container">
                    <div className="bg-shapeTwo d-lg-block d-md-none"></div>
                </div> */}
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-12">
                        {/* Section Heading */}
                        <div className="section-heading">
                        <h2 className="section-heading__mainText">{data.headingTwo}</h2>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    
                    {data.featureData_1.map((item, idx) => {
                        return(
                            <div key={`ft_${idx}`} className="col-12 col-md-6 col-lg-4">
                                <div className="section-items text-center p-4">
                                    <div className="featured-icon section-items__image mb-3">
                                        <img className="hover-zoom" src={item.image}/>
                                    </div>
                                    <div className="icon-text section-items__text">
                                        <h3 className="mb-2">{item.title}</h3>
                                        <p>{item.text}</p>
                                        <hr style={{borderTop: '1.5px solid grey'}}></hr>
                                        <p style={{marginTop: '0.5rem', marginBottom: '1rem'}}>{item.subtext}</p>
                                        <p style={{display: 'inline-block',fontSize: '50px', fontWeight: '700'}}>{item.price}</p>
                                        <span>{item.currency}</span>
                                    </div>
                                    <div className="section-items__action">
                                    <a href="https://m.me/PEPExpressCargoUAE">
                                    <button className="btn btn-md btn-block mb-2">Order Now</button>
                                    </a>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    </div>
                </div>
            </section>
        </>
        );
}

export default FeatureSection;