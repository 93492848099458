import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import Breadcrumb from '../Blogs/Breadcrumb';
import ServiceTwo from './ServiceTwo'
import ServiceOne from './ServiceOne'
import ServiceThree from './ServiceThree'

import FooterSection from '../FooterSection/Footer';
import "./scss/index.scss"

class ServicePage extends Component {
    render() {
        return (
            <div>
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <Header imageData={"/img/logo-white.png"} />
                    <div className="services__background">
                    <Breadcrumb title="Services" />
                    </div>
                    <div className="services__image">
                    <img src="/img/PEP_Banner.png"/>
                    </div>
                    <ServiceOne />
                    <ServiceTwo />
                    <ServiceThree />

                    {/* <DownloadSection />
                    <ContactSection /> */}
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default ServicePage;