import React from "react";

function ServiceThree () {
  const data = {
    "heading": "TV Size:",
    "headingTwo": "Wooden Crate included for Visayas/Mindanao",
    "headingThree": "Send happiness to your love ones with our TV cargo",
    "headingFour": "More productivity with less effort",
    "headingFive": "Organize all your media content easily",
    "headingSix": "Work faster with powerful filters",
    "headingSeven": "Stay connected with all of your friends",
    "buttonText": "Learn More",
    "thumbOne": "/img/features_thumb.png",
    "thumbTwo": "/img/service_thumb_2.png",
    "thumbThree": "img/featureThree.jpg",
    "thumbFour": "/img/discover_thumb.png",
    "thumbFive": "/img/service_thumb_1.png",
    "thumbSix": "/img/discover_thumb_2.png",
    "serviceData": [
      {
        "id": 1,
        "text": "32 inches"
      },
      {
        "id": 2,
        "text": "37-39 inches"
      },
      {
        "id": 3,
        "text": "40-43 inches"
      },
      {
        "id": 4,
        "text": "45-47 inches"
      },
      {
        "id": 5,
        "text": "48-50 inches"
      },
      {
        "id": 6,
        "text": "55-60 inches"
      },
      {
        "id": 7,
        "text": "65 inches"
      }
    ]
  }
    return (
      <section className="section service-area bg-inherit overflow-hidden ptb_100">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-5 order-2 order-lg-1 align-self-center">
              {/* Service Text */}
              <div className="service-text pt-4 pt-lg-0 px-0 px-lg-4">
                {/* <h2 className="text-capitalize mb-4">{this.state.data.headingThree}</h2> */}
                <h2 className="text-capitalize mb-3">TV Sea Cargo Rates</h2>
                <p className="mb-3">{data.headingThree}</p>
                <span>{data.heading}</span>
                {/* Service List */}
                <ul style={{columnCount: 2}}className="service-list style-two">
                  {/* Single Service */}
                  {data.serviceData.map((item, idx) => {
                    return (
                      <li key={`sth_${idx}`} className="single-service py-2">
                        <div className="service-text">
                          <p>{item.text}</p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <span className="d-block mt-2"><strong>NOTE: </strong>{data.headingTwo}</span>
                {/* <a href="/#" className="btn btn-bordered mt-4">
                  {this.state.data.buttonText}
                </a> */}
                <a href="https://m.me/PEPExpressCargoUAE" className="btn btn-bordered mt-4">
                Order Now
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-7 order-1 order-lg-2 d-md-block">
              {/* Service Thumb */}
              <div className="service-thumb mx-auto">
                <img src={data.thumbThree} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default ServiceThree;
